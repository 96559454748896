import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Landing } from "./pages/landing";
import Success from "./pages/success";
import { ToastContainer } from "react-toastify";

const App = () => {
  return (
    <>
      <ToastContainer  />
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/success" element={<Success />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
