import React from "react";
import men from "../images/handsome-middle-eastern-businessman-standing-by-bu-2023-11-27-05-15-32-utc.png";
import animal from "../images/animal.png";

export const Accuindex = () => {
  return (
    <div className="bg-[#EAEAEA]">
      <div className="flex flex-col-reverse md:flex-row  justify-center">
        <div className="md:w-2/3 w-full   flex md:justify-end justify-center">
          <img
            src={men}
            alt="Businessman"
            className=" pt-4 md:pt-0 md:h-[400px] md:w-[400px] lg:h-[500px] lg:w-[500px]  object-cover md:ml-8"
            style={{ marginBottom: "-12px" ,  }}
          />
        </div>
        <div
          className="relative text-center md:text-left w-full bg-contain bg-no-repeat "
          style={{ backgroundImage: `url(${animal})`, backgroundPosition: 'center bottom', /* Aligns image to the center horizontally and bottom vertically */
          backgroundSize: 'contain', /* Ensures the whole image is visible */}}
        >
          <div className="w-full h-[100%] flex flex-col justify-center md:items-start items-center">
            <div className="md:mx-12 lg:mt-36 md:mt-28">
              <p 
                className="text-2xl md:text-6xl lg:text-8xl xl:text-9xl text-[#9A2168]"
                style={{
                  fontFamily: "Tajawal, system-ui",
                  fontWeight: "800",
                }}
              >
                AccuIndex
              </p>
              <p
                className="text-2xl md:text-2xl lg:text-4xl xl:text-5xl text-center md:text-end  text-[#1E3767]"
                style={{
                  fontFamily: "Tajawal, system-ui",
                  fontWeight: "800",
                }}
              >
                معك خطوة بخطوة
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
