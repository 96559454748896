import React from "react";
import mobile from "../images/j-mobile.png";
export const Description = () => {
  return (
    <div className="  flex flex-col md:flex-row items-center p-7 pt-48 bg-[#EAEAEA] justify-center ">
      <div className="flex flex-col md:items-end items-center text-center md:mr-1 md:p-4 md:text-end flex-1 xl:mr-10">
        <h1
          className="text-2xl font-bold text-[#9A2168] lg:text-[40px] xl:text-4xl 2xl:text-5xl mb-5 "
          style={{
            fontFamily: "Tajawal, sans-serif",
          }}
        >
          تبحث عن الشريك{" "}
        </h1>
        <h1
          className="text-2xl font-bold text-[#1E3767] lg:text-[40px] xl:text-4xl 2xl:text-5xl"
          style={{
            fontFamily: "Tajawal, sans-serif",
          }}
        >
          المثالي لتداولاتك؟
        </h1>

        <div className="my-6 text-[#9A2168] font-bold md:text-[20px] text-[16px] flex" style={{
             fontfamily: "Poppins, sans-serif",
          }}>
          {/* <p> !الآن أقرب إليك في الأردن</p>
          <p className="mx-1" style={{
             fontfamily: "Poppins, sans-serif",
          }}> Accuindex</p> */}
        </div>

        <p className=" tracking-wide text-sm text-[#14192E] text-wrap xl:w-96 lg:w-96 opacity-[0.7]">
        تداول بسهولة. استمتع بالتداول على منصة مرنة

وسهلة الاستخدام والتي تتميز بموثوقية وشفافية عالية مع بطاقة



          <span
            className="text-[#9A2168] font-bold"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            AccuPay
          </span>
          التي تضمن لك الإيداع والسحب الفوري

        </p>
        <a href="#box">
        <button
          className="bg-[#9A2168] mt-8 md:w-44 md:h-14 w-32 h-12 text-[19px] text-[#EAEAEA] rounded-sm "
          style={{
            fontFamily: "Tajawal, system-ui",
            fontWeight: "500",
          }}
        >
          ابدأ التداول الآن
        </button>
        </a>
      </div>
      <div className="flex-1 w-full xl:mr-40 2xl:mr-80">
        <img src={mobile} alt="" className="max-w-full h-auto mt-40 hidden lg:block"
        />
      </div>
    </div>
  );
};

