import React from "react";
import { Nav, Slides ,Description , Menu , Accuindex , Footer} from "../../components";
export function Landing() {
  return (
    <>
      <Nav />
      <Slides />
      <Description />
      <Menu />
      <Accuindex />
      <Footer/>
    </>
  );
}
