import React from "react";
import video from "../videos/Jordan.mp4";

export const Slides = () => {
  return (
    <div className="w-full h-full flex justify-center items-center relative">
      <video
        className="w-full h-[250px] lg:h-auto object-cover"
        autoPlay
        muted
        loop
        playsInline
        src={video}
      >
        Your browser does not support the video tag.
      </video>
      <div
        className="absolute text-center flex flex-col justify-center"
        style={{
          fontFamily: "Tajawal, sans-serif",
        }}
      >
        <p
          dir="rtl"
          className="text-[#EAEAEA] lg:my-1  font-extrabold md:text-3xl lg:text-5xl text-2xl"
        >
          بوابتك إلى الأسواق المالية العالمية
        </p>
        <div dir="rtl" className="flex items-center justify-center">
          <p
            className="text-[#9A2168] lg:my-3 my-1  font-extrabold md:text-3xl lg:text-5xl text-2xl"
            style={{
              fontFamily: "Tajawal, Poppins, sans-serif",
            }}
          >
ابدأ تداول المنتجات المالية عبر منصة تداول آمنة           </p>
        </div>

        <p
          dir="rtl"
          className="text-[#EAEAEA] lg:my-1 md:text-[20px] lg:mx-22 text-[14px]  text-center mx-16"
          style={{
            fontFamily: "Tajawal, sans-serif",
          }}
        >
          تداول برافعة مالية تصل إلى{" "}
          <span
            className="text-[#9A2168] font-bold"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            1:400
          </span>{" "}
          مع إيداع وسحب فوري عبر بطاقة AccuPay
        </p>
        <div>
        <a href="#box">

          <button
            className="bg-[#9A2168] mt-8 md:w-44 md:h-14 w-32 h-12 text-[19px] text-[#EAEAEA] rounded-sm"
            style={{
              fontFamily: "Tajawal, system-ui",
              fontWeight: "500",
            }}
          >
            ابدأ التداول الآن
          </button>
          </a>
        </div>
      </div>
    </div>
  );
};
