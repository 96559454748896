import React from "react";
import dollar from "../images/dollar.png";
import compass from "../images/compass.png";
import men from "../images/men.png";

export const Menu = () => {
  return (
    <div className="bg-[#EAEAEA]">
      <div className=" text-2xl md:text-4xl lg:text-5xl text-center text-[#1E3767] font-bold">
        <p>؟ AccuIndex لماذا تختار</p>
      </div>
      <div className="container px-10 lg:px-10 lg:py-2 py-2 mx-auto">
        <div className="flex flex-col-reverse md:flex-row items-center justify-center">
          <div className="lg:w-1/4 md:w-1/3 w-full mb-2 p-1 xl:p-16">
            <div className="h-full text-center">
              <img
                src={men}
                alt=""
                className="lg:w-16 lg:h-16 w-12 h-12 lg:mb-3 mb-2 object-cover object-center inline-block border-2 border-gray-200 bg-gray-100"
              />
              <p
                className="leading-relaxed text-[17px] mb-2 text-[#1E3767]"
                style={{
                  fontFamily: "Tajawal, system-ui",
                  fontWeight: "800",
                }}
              >
وسيط مرخص عالمياً               </p>
              <p
                className="text-[#14192E] text-[14px]" dir="rtl"
                style={{ fontFamily: "Tajawal, system-ui",
                  fontWeight: "400" }}
              >
               انضم إلى عائلة المتداولين الناجحين في منطقة الشرق الأوسط وشمال أفريقيا مع Accuindex
              </p>
            </div>
          </div>
          <div className="lg:w-1/4 md:w-1/3 w-full mb-3 xl:mb-4 p-1 xl:p-20 md:p-8">
            <div className="h-full text-center">
              <img
                src={dollar}
                alt=""
                className="lg:w-16 lg:h-16 w-12 h-12 lg:mb-3 mb-2 object-cover object-center inline-block border-2 border-gray-200 bg-gray-100"
              />
              <p
                className="leading-relaxed text-[17px] mb-2 text-[#1E3767]"
                style={{
                  fontFamily: "Tajawal, system-ui",
                  fontWeight: "800",
                }}
              >
                إيداع وسحب فوري
              </p>
              <p
                className="text-[#14192E] text-[14px] "dir="rtl"
                style={{ fontFamily: "Tajawal, system-ui",
                  fontWeight: "400" }}
              >
               تمتع بسهولة إيداع وسحب أرباحك مباشرةً عبر بطاقة <br />AccuPay
              </p>
            </div>
          </div>
          <div className="lg:w-1/4 md:w-1/3 w-full mb-2 p-4  xl:p-16">
            <div className="h-full text-center">
              <img
                src={compass}
                alt=""
                className="lg:w-16 lg:h-16 w-12 h-12 lg:mb-3 mb-2 object-cover object-center inline-block border-2 border-gray-200 bg-gray-100"
              />
              <p
                className="leading-relaxed text-[14px] mb-2 text-[#1E3767]"
                style={{
                  fontFamily: "Tajawal, system-ui",
                  fontWeight: "800",
                }}
              >
                رافعة مالية تصل إلى1:400
              </p>
              <p
                className="text-[#14192E] text-[14px]"
                style={{ fontFamily: "Tajawal, system-ui",
                  fontWeight: "400" }}
              >
                ضاعف قوتك الشرائية واستفد<br/> من فرص السوق بأعلى رافعة <br />مالية متاحة
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
