import React from "react";
import logo from "../images/top-logo.png";

export const Nav = () => {
  return (
    <div className="bg-gray-300 h-10 lg:h-20 flex justify-center items-center lg:h-15 md:h-12 ">
      <img src={logo} alt="Logo" className="h-8 md:h-10 lg:h-16" />
    </div>
  );
};

