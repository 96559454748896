import React from 'react'
import { Success } from '../../components'
const index = () => {
  return (
    <>
      <Success/>
    </>
  )
}

export default index
